@import "../../common/variables.scss";
.calender {
  justify-content: center;
  align-items: center;
  margin-top: 20vh;
}
.calender-img{
  width: 100%;
  height: 100%;
}

.container-dashboard{
  justify-content: space-evenly;
}

.card-leaves{
 justify-content: center;
}
.card-inside{
  color: $whiteColor;
}
.footer-card{
  font-size: 12px;
}