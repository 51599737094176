@import "../../common/variables.scss";
.top {
    background-color: #a9a9a9;
    width: 100%;
    height: 2.5rem;
    border: solid 1px $blackColor;
    font-weight: bold;
    line-height: 2.5rem;
    text-align: left;
    padding-left: 5vh;
    margin-top: 4vh;
  
}
.fliter-option {
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
}
.data-grid {
    box-shadow: 0px 3px 6px #C2DAF9;    
}
.wms-heading {
    font-weight: bolder !important;
    padding-top: 2vh;
    padding-left: 2vh;
}
.tms-inbox-button{
    background-color: $whiteColor;
    color: $buttonColor;
    border-color: $buttonColor;
    border-radius: 0px !important;
    box-shadow: $buttonColor;
}
// ag grid theme custom
.ag-theme-balham .ag-header {
    background-color: #ffffff;
    color: #000000 !important;
    font-size: 16px !important;
    font-weight: bold;
    text-align: center;
    font-style: 15px/21px Open Sans !important;
    height: 57px !important;
    padding-top: 6px;
    border-bottom: 1px solid #ececec !important;
}
.ag-theme-balham .ag-paging-panel {
    border-bottom: 1px solid #ececec !important;
    color: rgba(0, 0, 0, 0.54);
    height: 32px;
    padding: 0 12px;
}
.ag-theme-balham .ag-root {
    border: 0px !important;
}
.ag-theme-balham .ag-header-cell::after, .ag-theme-balham .ag-header-group-cell::after {
    border-right: 0px !important;
    content:"";
    height: 16px;
    margin-top: 8px;
    position: absolute;
    text-indent: -2000px;
    top: 0;
}
.ag-theme-balham {
    -webkit-font-smoothing: antialiased;
    background-color: white;
    color: #000;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 500;
    font-size: 14px !important;
    line-height: normal;
}
.active {
    background-color: $buttonColor;
    color: $whiteColor;
}
.ag-paging-panel .ag-paging-row-summary-panel {
    display: none !important;
}
.wms-inbox-filter{
    background-color: #ffffff !important;
    // color: #338AFD !important;
    text-transform: none !important;
    border-color: #338AFD !important;
    border-radius: 0px !important;
}
.ag-theme-balham .ag-row {
    border-color: #ececec !important;
    cursor: pointer !important;
}