@import "../../common/variables.scss";
.data-grid{
    font-size: 15px;   
}
.inbox-submission-percentage{
    color: #338AFD;
}
.ag-theme-balham .ag-header {
    background-color: #338AFD;
    color: $whiteColor;
    text-align: center;
    font-style: 18px/24px Open Sans;
}
.ag-header-cell-label {
    display: initial;
}
.ag-row .ag-cell {
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 1vh !important;
}
.inbox-dialog-button{
    background-color: #338AFD !important;
    color:$whiteColor ;
}
.inbox-search-hr {
    vertical-align: middle;
    text-transform: capitalize !important;
}

.inbox-container {
    flex-direction: column;
}
.inbox-container-hr{
    flex: 1;
    vertical-align: middle;
}
.inbox-container-emp{
    flex: 1;
    vertical-align: middle;
}
.active-inbox-container{
    flex-wrap: wrap;
 }
.inbox-search-emp{
    text-transform: capitalize !important;
    vertical-align: middle;
    margin-left: 62vw;
}

.session-head-card{
    align-items: center;
    box-shadow: 0px 3px 6px #7B92AF69 !important;
}

.inbox-checkbox{
    color: #338AFD !important;
}

.inbox-edit-button{
 color: #338AFD;
 cursor: pointer;
}
.active-inbox-container-emp{
    flex: 1;
    vertical-align: middle;
    justify-content: center;
}
.disable {
    color: #808080 !important;
}
.no-session-card{
    justify-content: center;
    margin: 5vh 20vw;
    font-size: 20px;
}