@import "../../common/variables.scss";
.feedbackstatuscontainer{
    min-height:90vh;
    margin-top: -1vw;
}
.Status-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
}
.filled-feedback-form-container{
    background-color: $whiteColor;
    margin: 1vh 5vw;
    padding: 1vh 0vw;
}

.status-heading{
    font-weight: 500;
    margin-bottom: 0.5vh;
}
.FeedbackStatus-header{
    display: flex;    
    justify-content: space-around;
    height: 7vh;
    padding:1vh 1vw !important;
    align-items: center;
    color:$whiteColor;
    font-size: 1em !important;
    font-weight: bold !important;
}

.FeedbackStatus-body{
    height: 40vh;
    overflow: auto;
}
.Status-container-card{
  background-color: $whiteColor;
  justify-content: space-between;
}

.status-card-container{
    justify-content: space-between;
}
.FeedbackStatus-inner-body-1{
    flex-direction: column;
}


.FeedbackStatus-inner-body-2{
    flex-direction: column;
    //margin-right: 3vw;
}
   
.data-size{
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 35px;
    color:#338AFD;
}
.FeedbackStatus-body{
    margin-top: 0.5vh;
    display: flex;    
    justify-content: space-around;
    height: 30vh;
    overflow: auto;
}
.flex-equal{
    flex-basis: 25%;
}
