//--------------------------------------------height--------------------------------------------
.h-1{height:1vh};
.h-2{height:2vh};
.h-3{height:3vh};
.h-4{height:4vh};
.h-5{height:5vh};
.h-6{height:6vh};
.h-7{height:7vh};
.h-8{height:8vh};
.h-9{height:9vh};
.h-10{height:10vh};
.h-11{height:11vh};
.h-12{height:12vh};
.h-13{height:13vh};
.h-14{height:14vh};
.h-15{height:15vh};
.h-16{height:16vh};
.h-17{height:17vh};
.h-18{height:18vh};
.h-19{height:19vh};
.h-20{height:20vh};
.h-21{height:21vh};
.h-22{height:22vh};
.h-23{height:23vh};
.h-24{height:24vh};
.h-25{height:25vh};
.h-26{height:26vh};
.h-27{height:27vh};
.h-28{height:28vh};
.h-29{height:29vh};
.h-30{height:30vh};
.h-31{height:31vh};
.h-32{height:32vh};
.h-33{height:33vh};
.h-34{height:34vh};
.h-35{height:35vh};
.h-36{height:36vh};
.h-37{height:37vh};
.h-38{height:38vh};
.h-39{height:39vh};
.h-40{height:40vh};
.h-41{height:41vh};
.h-42{height:42vh};
.h-43{height:43vh};
.h-44{height:44vh};
.h-45{height:45vh};
.h-46{height:46vh};
.h-47{height:47vh};
.h-48{height:48vh};
.h-49{height:49vh};
.h-50{height:50vh};
.h-51{height:51vh};
.h-52{height:52vh};
.h-53{height:53vh};
.h-54{height:54vh};
.h-55{height:55vh};
.h-56{height:56vh};
.h-57{height:57vh};
.h-58{height:58vh};
.h-59{height:59vh};
.h-60{height:60vh};
.h-61{height:61vh};
.h-62{height:62vh};
.h-63{height:63vh};
.h-64{height:64vh};
.h-65{height:65vh};
.h-66{height:66vh};
.h-67{height:67vh};
.h-68{height:68vh};
.h-69{height:69vh};
.h-70{height:70vh};
.h-71{height:71vh};
.h-72{height:72vh};
.h-73{height:73vh};
.h-74{height:74vh};
.h-75{height:75vh};
.h-76{height:76vh};
.h-77{height:77vh};
.h-78{height:78vh};
.h-79{height:79vh};
.h-80{height:80vh};
.h-81{height:81vh};
.h-82{height:82vh};
.h-83{height:83vh};
.h-84{height:84vh};
.h-85{height:85vh};
.h-86{height:86vh};
.h-87{height:87vh};
.h-88{height:88vh};
.h-89{height:89vh};
.h-90{height:90vh};
.h-91{height:91vh};
.h-92{height:92vh};
.h-93{height:93vh};
.h-94{height:94vh};
.h-95{height:95vh};
.h-96{height:96vh};
.h-97{height:97vh};
.h-98{height:98vh};
.h-99{height:99vh};
.h-100{height:100vh};

//--------------------------------------------max-height------------------------------------------------------

.max-h-1{max-height:1vh};
.max-h-2{max-height:2vh};
.max-h-3{max-height:3vh};
.max-h-4{max-height:4vh};
.max-h-5{max-height:5vh};
.max-h-6{max-height:6vh};
.max-h-7{max-height:7vh};
.max-h-8{max-height:8vh};
.max-h-9{max-height:9vh};
.max-h-10{max-height:10vh};
.max-h-11{max-height:11vh};
.max-h-12{max-height:12vh};
.max-h-13{max-height:13vh};
.max-h-14{max-height:14vh};
.max-h-15{max-height:15vh};
.max-h-16{max-height:16vh};
.max-h-17{max-height:17vh};
.max-h-18{max-height:18vh};
.max-h-19{max-height:19vh};
.max-h-20{max-height:20vh};
.max-h-21{max-height:21vh};
.max-h-22{max-height:22vh};
.max-h-23{max-height:23vh};
.max-h-24{max-height:24vh};
.max-h-25{max-height:25vh};
.max-h-26{max-height:26vh};
.max-h-27{max-height:27vh};
.max-h-28{max-height:28vh};
.max-h-29{max-height:29vh};
.max-h-30{max-height:30vh};
.max-h-31{max-height:31vh};
.max-h-32{max-height:32vh};
.max-h-33{max-height:33vh};
.max-h-34{max-height:34vh};
.max-h-35{max-height:35vh};
.max-h-36{max-height:36vh};
.max-h-37{max-height:37vh};
.max-h-38{max-height:38vh};
.max-h-39{max-height:39vh};
.max-h-40{max-height:40vh};
.max-h-41{max-height:41vh};
.max-h-42{max-height:42vh};
.max-h-43{max-height:43vh};
.max-h-44{max-height:44vh};
.max-h-45{max-height:45vh};
.max-h-46{max-height:46vh};
.max-h-47{max-height:47vh};
.max-h-48{max-height:48vh};
.max-h-49{max-height:49vh};
.max-h-50{max-height:50vh};
.max-h-51{max-height:51vh};
.max-h-52{max-height:52vh};
.max-h-53{max-height:53vh};
.max-h-54{max-height:54vh};
.max-h-55{max-height:55vh};
.max-h-56{max-height:56vh};
.max-h-57{max-height:57vh};
.max-h-58{max-height:58vh};
.max-h-59{max-height:59vh};
.max-h-60{max-height:60vh};
.max-h-61{max-height:61vh};
.max-h-62{max-height:62vh};
.max-h-63{max-height:63vh};
.max-h-64{max-height:64vh};
.max-h-65{max-height:65vh};
.max-h-66{max-height:66vh};
.max-h-67{max-height:67vh};
.max-h-68{max-height:68vh};
.max-h-69{max-height:69vh};
.max-h-70{max-height:70vh};
.max-h-71{max-height:71vh};
.max-h-72{max-height:72vh};
.max-h-73{max-height:73vh};
.max-h-74{max-height:74vh};
.max-h-75{max-height:75vh};
.max-h-76{max-height:76vh};
.max-h-77{max-height:77vh};
.max-h-78{max-height:78vh};
.max-h-79{max-height:79vh};
.max-h-80{max-height:80vh};
.max-h-81{max-height:81vh};
.max-h-82{max-height:82vh};
.max-h-83{max-height:83vh};
.max-h-84{max-height:84vh};
.max-h-85{max-height:85vh};
.max-h-86{max-height:86vh};
.max-h-87{max-height:87vh};
.max-h-88{max-height:88vh};
.max-h-89{max-height:89vh};
.max-h-90{max-height:90vh};
.max-h-91{max-height:91vh};
.max-h-92{max-height:92vh};
.max-h-93{max-height:93vh};
.max-h-94{max-height:94vh};
.max-h-95{max-height:95vh};
.max-h-96{max-height:96vh};
.max-h-97{max-height:97vh};
.max-h-98{max-height:98vh};
.max-h-99{max-height:99vh};
.max-h-100{max-height:100vh};

//--------------------------------------------min-height------------------------------------------------------

.min-h-1{min-height:1vh};
.min-h-2{min-height:2vh};
.min-h-3{min-height:3vh};
.min-h-4{min-height:4vh};
.min-h-5{min-height:5vh};
.min-h-6{min-height:6vh};
.min-h-7{min-height:7vh};
.min-h-8{min-height:8vh};
.min-h-9{min-height:9vh};
.min-h-10{min-height:10vh};
.min-h-11{min-height:11vh};
.min-h-12{min-height:12vh};
.min-h-13{min-height:13vh};
.min-h-14{min-height:14vh};
.min-h-15{min-height:15vh};
.min-h-16{min-height:16vh};
.min-h-17{min-height:17vh};
.min-h-18{min-height:18vh};
.min-h-19{min-height:19vh};
.min-h-20{min-height:20vh};
.min-h-21{min-height:21vh};
.min-h-22{min-height:22vh};
.min-h-23{min-height:23vh};
.min-h-24{min-height:24vh};
.min-h-25{min-height:25vh};
.min-h-26{min-height:26vh};
.min-h-27{min-height:27vh};
.min-h-28{min-height:28vh};
.min-h-29{min-height:29vh};
.min-h-30{min-height:30vh};
.min-h-31{min-height:31vh};
.min-h-32{min-height:32vh};
.min-h-33{min-height:33vh};
.min-h-34{min-height:34vh};
.min-h-35{min-height:35vh};
.min-h-36{min-height:36vh};
.min-h-37{min-height:37vh};
.min-h-38{min-height:38vh};
.min-h-39{min-height:39vh};
.min-h-40{min-height:40vh};
.min-h-41{min-height:41vh};
.min-h-42{min-height:42vh};
.min-h-43{min-height:43vh};
.min-h-44{min-height:44vh};
.min-h-45{min-height:45vh};
.min-h-46{min-height:46vh};
.min-h-47{min-height:47vh};
.min-h-48{min-height:48vh};
.min-h-49{min-height:49vh};
.min-h-50{min-height:50vh};
.min-h-51{min-height:51vh};
.min-h-52{min-height:52vh};
.min-h-53{min-height:53vh};
.min-h-54{min-height:54vh};
.min-h-55{min-height:55vh};
.min-h-56{min-height:56vh};
.min-h-57{min-height:57vh};
.min-h-58{min-height:58vh};
.min-h-59{min-height:59vh};
.min-h-60{min-height:60vh};
.min-h-61{min-height:61vh};
.min-h-62{min-height:62vh};
.min-h-63{min-height:63vh};
.min-h-64{min-height:64vh};
.min-h-65{min-height:65vh};
.min-h-66{min-height:66vh};
.min-h-67{min-height:67vh};
.min-h-68{min-height:68vh};
.min-h-69{min-height:69vh};
.min-h-70{min-height:70vh};
.min-h-71{min-height:71vh};
.min-h-72{min-height:72vh};
.min-h-73{min-height:73vh};
.min-h-74{min-height:74vh};
.min-h-75{min-height:75vh};
.min-h-76{min-height:76vh};
.min-h-77{min-height:77vh};
.min-h-78{min-height:78vh};
.min-h-79{min-height:79vh};
.min-h-80{min-height:80vh};
.min-h-81{min-height:81vh};
.min-h-82{min-height:82vh};
.min-h-83{min-height:83vh};
.min-h-84{min-height:84vh};
.min-h-85{min-height:85vh};
.min-h-86{min-height:86vh};
.min-h-87{min-height:87vh};
.min-h-88{min-height:88vh};
.min-h-89{min-height:89vh};
.min-h-90{min-height:90vh};
.min-h-91{min-height:91vh};
.min-h-92{min-height:92vh};
.min-h-93{min-height:93vh};
.min-h-94{min-height:94vh};
.min-h-95{min-height:95vh};
.min-h-96{min-height:96vh};
.min-h-97{min-height:97vh};
.min-h-98{min-height:98vh};
.min-h-99{min-height:99vh};
.min-h-100{min-height:100vh};