.spinner-border-sm {
    width: .8rem;
    height: .8rem;
    border-width: .2em;
}
.spinner-border {
    display: inline-block;
    vertical-align: text-bottom;
    border: .2em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
.sr-only {
    width: 1px;
    height: 1px;
    padding: 1;
    margin: 2px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
    text-transform: capitalize !important;
    position: inherit !important;
}
@keyframes spinner-border {
    to { transform: rotate(360deg); }
  }

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.spinner-grow {
    display: inline-block;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite;
}
@keyframes spinner-grow {
    0% {
      transform: scale(0);
    }
    50% {
      opacity: 1;
    }
  }