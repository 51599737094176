@import "../../common/variables.scss";

.create-session-container {
    flex-direction: column;
    width: 100em;
    min-height: 90.2vh;
  }

  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
  }
  .centered{
    margin: 0 auto;
    width: 80%;
  }
  @media screen and (min-width: 480px) {
      .centered{
            margin: 0 auto;
            width: 95%;
          }
   }

.MuiOutlinedInput-input {
  padding: 11px 14px!important;
}

.locationInput {
  margin-left: 1.8vw !important;
}

.titleInput {
  margin-left: 6.25vw !important;
}
.createSesionHeading {
    width: 100%;
    min-height: 20px;
    margin-left:33vw !important;

}
// .selectBoxcontrol {
//     display: inline-block;
//     background-color:$whiteColor;
//     border-radius: 5px;
//     margin-left: 3.25em;
//     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
//     color: hsl(0,0%,50%);
//     padding-left: 2px !important;
// }
.reactSelectDiv{
  display: inline-flex;
}
.reactSelect{
  width: 45.8vw !important;
  // margin-left: 1.4vw;
  // padding-left: 35px !important;   
}
// .presentedBy{
//   width: 43vw;
//   margin-left: 2vw;
// }
// .errorTab-create-session {
//   color: #ff0000 !important;
//   font-size: 1.5em;
//   margin-left:8vw;
// }

.session-cancel-btn{
  margin-left: 34vw !important;
  }
.session-create-btn{
  color: $whiteColor;
}
.btn-pop{
  color: 	#000000 !important;
  background-color: $whiteColor;
}