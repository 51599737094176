//---------------------------------padding------------------------------
.p-0{padding: 0vh 0vw !important;}
.p-1{ padding: 0.5vh 0.5vw !important; } 
.p-2{ padding: 1vh 1vw !important; } 
.p-3{ padding: 1.5vh 1.5vw !important; } 
.p-4{ padding: 2vh 2vw !important; } 
.p-5{ padding: 2.5vh 2.5vw !important; } 
.p-6{ padding: 3vh 3vw !important; } 
.p-7{ padding: 3.5vh 3.5vw !important; } 
.p-8{ padding: 4vh 4vw !important; } 
.p-9{ padding: 4.5vh 4.5vw !important; } 
.p-10{ padding: 5vh 5vw !important; }
.p-11{ padding: 5.5vh 5.5vw !important; } 
.p-12{ padding: 6vh 6vw !important; } 
.p-13{ padding: 6.5vh 6.5vw !important; } 
.p-14{ padding: 7vh 7vw !important; } 
.p-15{ padding: 7.5vh 7.5vw !important; } 
.p-16{ padding: 8vh 8vw !important; } 
.p-17{ padding: 8.5vh 8.5vw !important; } 
.p-18{ padding: 9vh 9vw !important; } 
.p-19{ padding: 9.5vh 9.5vw !important; } 
.p-20{ padding: 10vh 10vw !important; }

//---------------------------------padding-left------------------------------
.pl-1{ padding-left: 0.5vw !important; } 
.pl-2{ padding-left: 1vw !important; } 
.pl-3{ padding-left: 1.5vw !important; } 
.pl-4{ padding-left: 2vw !important; } 
.pl-5{ padding-left: 2.5vw !important; } 
.pl-6{ padding-left: 3vw !important; } 
.pl-7{ padding-left: 3.5vw !important; } 
.pl-8{ padding-left: 4vw !important; } 
.pl-9{ padding-left: 4.5vw !important; } 
.pl-10{ padding-left: 5vw !important; } 
.pl-11{ padding-left: 5.5vw !important; } 
.pl-12{ padding-left: 6vw !important; } 
.pl-13{ padding-left: 6.5vw !important; } 
.pl-14{ padding-left: 7vw !important; } 
.pl-15{ padding-left: 7.5vw !important; } 
.pl-16{ padding-left: 8vw !important; } 
.pl-17{ padding-left: 8.5vw !important; } 
.pl-18{ padding-left: 9vw !important; } 
.pl-19{ padding-left: 9.5vw !important; } 
.pl-20{ padding-left: 10vw !important; }

//-----------------------------------padding-right-----------------------------------------------
.pr-1{ padding-right: 0.5vw !important; } 
.pr-2{ padding-right: 1vw !important; } 
.pr-3{ padding-right: 1.5vw !important; } 
.pr-4{ padding-right: 2vw !important; } 
.pr-5{ padding-right: 2.5vw !important; } 
.pr-6{ padding-right: 3vw !important; } 
.pr-7{ padding-right: 3.5vw !important; } 
.pr-8{ padding-right: 4vw !important; } 
.pr-9{ padding-right: 4.5vw !important; } 
.pr-10{ padding-right: 5vw !important; } 
.pr-11{ padding-right: 5.5vw !important; } 
.pr-12{ padding-right: 6vw !important; } 
.pr-13{ padding-right: 6.5vw !important; } 
.pr-14{ padding-right: 7vw !important; } 
.pr-15{ padding-right: 7.5vw !important; } 
.pr-16{ padding-right: 8vw !important; } 
.pr-17{ padding-right: 8.5vw !important; } 
.pr-18{ padding-right: 9vw !important; } 
.pr-19{ padding-right: 9.5vw !important; } 
.pr-20{ padding-right: 10vw !important; }

//-----------------------------------------padding-top-----------------------------------------------
.pt-1{ padding-top: 0.5vh !important; } 
.pt-2{ padding-top: 1vh !important; } 
.pt-3{ padding-top: 1.5vh !important; } 
.pt-4{ padding-top: 2vh !important; } 
.pt-5{ padding-top: 2.5vh !important; } 
.pt-6{ padding-top: 3vh !important; } 
.pt-7{ padding-top: 3.5vh !important; } 
.pt-8{ padding-top: 4vh !important; } 
.pt-9{ padding-top: 4.5vh !important; } 
.pt-10{ padding-top: 5vh !important; } 
.pt-11{ padding-top: 5.5vh !important; } 
.pt-12{ padding-top: 6vh !important; } 
.pt-13{ padding-top: 6.5vh !important; } 
.pt-14{ padding-top: 7vh !important; } 
.pt-15{ padding-top: 7.5vh !important; } 
.pt-16{ padding-top: 8vh !important; } 
.pt-17{ padding-top: 8.5vh !important; } 
.pt-18{ padding-top: 9vh !important; } 
.pt-19{ padding-top: 9.5vh !important; } 
.pt-20{ padding-top: 10vh !important; }

//--------------------------------------padding-bottom-------------------------------------------
.pb-1{ padding-bottom: 0.5vh !important; } 
.pb-2{ padding-bottom: 1vh !important; } 
.pb-3{ padding-bottom: 1.5vh !important; } 
.pb-4{ padding-bottom: 2vh !important; } 
.pb-5{ padding-bottom: 2.5vh !important; } 
.pb-6{ padding-bottom: 3vh !important; } 
.pb-7{ padding-bottom: 3.5vh !important; } 
.pb-8{ padding-bottom: 4vh !important; } 
.pb-9{ padding-bottom: 4.5vh !important; } 
.pb-10{ padding-bottom: 5vh !important; } 
.pb-11{ padding-bottom: 5.5vh !important; } 
.pb-12{ padding-bottom: 6vh !important; } 
.pb-13{ padding-bottom: 6.5vh !important; } 
.pb-14{ padding-bottom: 7vh !important; } 
.pb-15{ padding-bottom: 7.5vh !important; } 
.pb-16{ padding-bottom: 8vh !important; } 
.pb-17{ padding-bottom: 8.5vh !important; } 
.pb-18{ padding-bottom: 9vh !important; } 
.pb-19{ padding-bottom: 9.5vh !important; } 
.pb-20{ padding-bottom: 10vh !important; }
