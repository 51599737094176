.card{
    max-width: 345px;
    width: 60%;
    margin-left: 30%;
    text-align: justify;
   
}
.card-img-top{ 
    height: 20%;
    width: 100%;
}
