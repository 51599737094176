#avatar{
    margin: 10px;
}

#BigAvatar{
    margin: 10px;
    width: 60px;
    height: 60px;
}

#orangeAvatar{
    margin: 10px;
    color: #fff;
    background-color: orangered;
}

#purpleAvatar{
    margin: 10px;
    color: #fff;
    background-color: purple;
}

#pinkAvatar{
    margin: 10px;
    color: #fff;
    background-color: pink;
}
#greenAvatar{
    margin: 10px;
    color: #fff;
    background-color: green;
}
