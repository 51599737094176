.register-label {
    float: left;
    width: 25%;
    margin-top: 3vh !important;
  }
  
.register-input-field {
    float: left;
    width: 75%;
    margin-top: 3vh !important;
  }
.error-tab {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4p;
}
.error-msg {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}