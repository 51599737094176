@import "../../common/variables.scss";

.session-head{
    display: flex;
    align-items: center;
}

 .MuiTooltip-tooltip{
     padding: 1vh !important;
     font-size: 12px !important;
     margin-left: 16vw !important;
     margin-top: 7vh !important;
 }

.primary-btn{       
    float: right;
    min-width: 10vw !important;
    margin-left: 15px !important;
    color:$whiteColor;
    text-transform: capitalize !important;
}

.disabled{
    color:#D5D5D5 !important;
}
#tooltip-2{
    text-align: center;
    padding: 10px;
    border-radius: 10px;  /* This defines tooltip text position */
    position: absolute;
    z-index: 1;
}
.active-session-head{
    display: flex;
   flex-wrap: nowrap;
}
.session-head-detail {
    text-align: center;
    padding: 0 12px;    
    width: 10vw;
//   white-space: nowrap;
//   overflow: hidden; 
//   text-overflow: ellipsis;
  }
.MuiCardContent-root:last-child{
    padding: 16px !important;
}
.card-head-label {
    font-style: Semibold 18px/24px Open Sans;
    font-size: 18px !important;
    font-weight: bold;
}
.card-head-data {
   // font-style: Regular 14px/19px Open Sans;
   font-size: 13px !important;
    margin-top: 10px;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // overflow: hidden;
}


