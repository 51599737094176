@import "../../common/variables.scss";
.wms-ui-maincontainer{
    height:100% ;
    background-color: $whiteColor;
}
.wms-ui-sidebar-container{
    width: 15em;
    left: 0;
    right: auto;
    border-right: 1px solid #eeeeee;
    top: 0;
    flex: 1 0 auto;
    outline: none;
    z-index: 1100;
    position: fixed;
    overflow-y: auto;
    flex-direction: column;
    color: $blackColor;
    box-shadow: 0px 3px 25px #BFCEF9 !important;
   }
   .wms-ui-sidebar-container{
    background: $whiteColor;
} 

.wms-ui-sidebar-a {
    color: $blackColor;
    width: 100%;
    letter-spacing: 0;
    text-transform: none;
    justify-content: flex-start;
    text-decoration: none;
    align-items: center;
}

.wms-ui-sidebar-a:hover {
    color: $whiteColor;
}
.active-a{
    color: $whiteColor;
}


  .wms-header-logo{
    width: 150px;
    height: 62px;
  }
.sidebar-ul {
    position: relative;
    list-style: none;
}
.sidebar-li {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
}

.sidebar-span {
    width: 100%;
    align-items: inherit;
    justify-content: inherit;
}

.sidebar-icon-div {
    color: $whiteColor;
    width: 24px;
    height: 24px;
    align-items: center;
    margin-right: 8px;
}
.sidebar-inner-container{
    height: 100%;
    flex-direction: column;
    
}
  .sidebar-item-expand-arrow {
    font-size: 1.2rem !important;
  }
  
  .sidebar-item-expand-arrow-expanded {
    color: #09bb12;  
  }

  .sidebar-ul-sub{
    .wms-ui-sidebar-a{
      padding-left: 24px;
    }
  }
