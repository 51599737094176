.div-margin {
    display: flex;
}
.form-labels {
    width: 25vh;
    margin-top: 20px !important;
    font-weight: bold !important;
}
.form-inputfield {
    width: 75vw;
    margin-top: 20px !important;
}