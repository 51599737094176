@import "../../common/variables.scss";
.feedback-container{ 
       width: 100%;
       flex-direction: column;
       align-items: center;
    }
.Feedbacks-container-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 2.5% 0%;
    background-color: $whiteColor;
    height:60vh;
    width:50em;
}
.Feedbacks-container-1{
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    margin: 1% 4%;
    padding-left:3%;
    background-color: $whiteColor;
    height:100vh;
    width:65em;
}

.feedback-header-card{
    margin: 1% 0% !important;
    padding-bottom: 0px !important;
    
}
.feedback-options{
    display: flex;
    flex-direction: column;
    margin-left: 35%;
}
.questions{
    text-align: center;
}
.options {
    cursor: pointer;
    border-radius: 40px !important;
    min-height: 1em;
    margin: 0.5em 0 !important;
    padding: 0.75em ;
    line-height: 1em;
    color: rgba(0,0,0,.87) !important;
    width: 40%;
    text-transform: capitalize !important;
}

.ui.inverted.button {   
    text-transform: uppercase !important;
    cursor: pointer;
    min-height: 1em;
    vertical-align: baseline;
    color: rgba(0,0,0,1);
    margin: 1em .5em 0 0;
    padding: .5em 1em .5em 1em;
    text-transform: none;
    line-height: 2em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 1rem;
     width: 15%;
     font-weight: 500;
}
.ui.inverted.button:disabled {
   color: #808080 !important;
   border:none !important;
} 

.selected {
    color: $whiteColor;
}
  
.feedbackForm-textArea{
    width: 70% !important;
    padding:0px !important;
}

.feedback-submit{

    margin-top: 2% !important;
    margin-bottom: 2% !important;
    margin-left: 43% !important;
    color: $whiteColor;
}
.feedbackform-dialogBox{
    margin-bottom: 2vh !important;

}
.dialog-button{
    color:#78909c;
    width: 10% !important;
}
