@import "./theme.scss";

$whiteColor: #ffffff !important;
$blackColor: #000000 !important;
$display: display;
$float: float;
$right: right;
$left: left;
$initial: initial;
$inherit: inherit;
$none: none;
$font: font;
$weight: weight;
$formfamilylabel: sans-serif;
$fontSizeLabel: 16px !important;
$fontWeightLabel: bold !important;
$buttonColor: #338AFD !important;


.theme-1{
    @include themeable(#F1F7FF !important,#338AFD !important,#B9B9B9 !important,#f7f7f7 !important, #338AFD !important,#338AFD !important,$whiteColor !important);
}
.theme-2{
    @include themeable(#f7f7f7 !important,#39a88e !important,#97bbc1 !important,#2a4054 !important, #344a5e !important,linear-gradient(315deg, #2a4054,   30%, #7690aa 70%) !important,#f8f8f9 !important);
}
.theme-3{
    @include themeable(#ffffff !important,#000000 !important,#818181 !important, #000000 !important, #818181 !important,linear-gradient(315deg, #000000,   30%, #818181 70%) !important,#f8f8f9 !important);
}

