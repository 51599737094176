@import "../../common/variables.scss";
.table-container{
    margin-left: auto;
    margin-right: auto;
    width: 21cm;
    height: 29.7cm;
}

.table-credential{
    width: inherit;
}

table{
    width: inherit;
    border-spacing: 0px;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
    text-align: center;
}

tr {
    //border-bottom: 1px solid black;
}
td, th{
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
    //border: 0.2px solid $blackColor;
}
.th{
    width: 2cm;
}

.td{
    width: 5cm;
    font-weight: bold;
    text-align: start;
}

.w-heading{
    text-align: center;
}

.w-para {
    margin-bottom: 0px;
}

.w-td-ques {
    text-align: left;
    padding-left: 5px;
}

.w-f-btn{
    position: inherit !important;
}
.data{
    text-align: start;
    padding-left: 1vw ;
}
