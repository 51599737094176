//--------------------------------------------width--------------------------------------------
.w-1{width:1vw !important};
.w-2{width:2vw !important};
.w-3{width:3vw !important};
.w-4{width:4vw !important};
.w-5{width:5vw !important};
.w-6{width:6vw !important};
.w-7{width:7vw !important};
.w-8{width:8vw !important};
.w-9{width:9vw !important};
.w-10{width:10vw !important};
.w-11{width:11vw !important};
.w-12{width:12vw !important};
.w-13{width:13vw !important};
.w-14{width:14vw !important};
.w-15{width:15vw !important};
.w-16{width:16vw !important};
.w-17{width:17vw !important};
.w-18{width:18vw !important};
.w-19{width:19vw !important};
.w-20{width:20vw !important};
.w-21{width:21vw !important};
.w-22{width:22vw !important};
.w-23{width:23vw !important};
.w-24{width:24vw !important};
.w-25{width:25vw !important};
.w-26{width:26vw !important};
.w-27{width:27vw !important};
.w-28{width:28vw !important};
.w-29{width:29vw !important};
.w-30{width:30vw !important};
.w-31{width:31vw !important};
.w-32{width:32vw !important};
.w-33{width:33vw !important};
.w-34{width:34vw !important};
.w-35{width:35vw !important};
.w-36{width:36vw !important};
.w-37{width:37vw !important};
.w-38{width:38vw !important};
.w-39{width:39vw !important};
.w-40{width:40vw !important};
.w-41{width:41vw !important};
.w-42{width:42vw !important};
.w-43{width:43vw !important};
.w-44{width:44vw !important};
.w-45{width:45vw !important};
.w-46{width:46vw !important};
.w-47{width:47vw !important};
.w-48{width:48vw !important};
.w-49{width:49vw !important};
.w-50{width:50vw !important};
.w-51{width:51vw !important};
.w-52{width:52vw !important};
.w-53{width:53vw !important};
.w-54{width:54vw !important};
.w-55{width:55vw !important};
.w-56{width:56vw !important};
.w-57{width:57vw !important};
.w-58{width:58vw !important};
.w-59{width:59vw !important};
.w-60{width:60vw !important};
.w-61{width:61vw !important};
.w-62{width:62vw !important};
.w-63{width:63vw !important};
.w-64{width:64vw !important};
.w-65{width:65vw !important};
.w-66{width:66vw !important};
.w-67{width:67vw !important};
.w-68{width:68vw !important};
.w-69{width:69vw !important};
.w-70{width:70vw !important};
.w-71{width:71vw !important};
.w-72{width:72vw !important};
.w-73{width:73vw !important};
.w-74{width:74vw !important};
.w-75{width:75vw !important};
.w-76{width:76vw !important};
.w-77{width:77vw !important};
.w-78{width:78vw !important};
.w-79{width:79vw !important};
.w-80{width:80vw !important};
.w-81{width:81vw !important};
.w-82{width:82vw !important};
.w-83{width:83vw !important};
.w-84{width:84vw !important};
.w-85{width:85vw !important};
.w-86{width:86vw !important};
.w-87{width:87vw !important};
.w-88{width:88vw !important};
.w-89{width:89vw !important};
.w-90{width:90vw !important};
.w-91{width:91vw !important};
.w-92{width:92vw !important};
.w-93{width:93vw !important};
.w-94{width:94vw !important};
.w-95{width:95vw !important};
.w-96{width:96vw !important};
.w-97{width:97vw !important};
.w-98{width:98vw !important};
.w-99{width:99vw !important};
.w-100{width:100vw !important};

//--------------------------------------------max-width------------------------------------------------------

.max-w-1{max-width:1vw !important};
.max-w-2{max-width:2vw !important};
.max-w-3{max-width:3vw !important};
.max-w-4{max-width:4vw !important};
.max-w-5{max-width:5vw !important};
.max-w-6{max-width:6vw !important};
.max-w-7{max-width:7vw !important};
.max-w-8{max-width:8vw !important};
.max-w-9{max-width:9vw !important};
.max-w-10{max-width:10vw !important};
.max-w-11{max-width:11vw !important};
.max-w-12{max-width:12vw !important};
.max-w-13{max-width:13vw !important};
.max-w-14{max-width:14vw !important};
.max-w-15{max-width:15vw !important};
.max-w-16{max-width:16vw !important};
.max-w-17{max-width:17vw !important};
.max-w-18{max-width:18vw !important};
.max-w-19{max-width:19vw !important};
.max-w-20{max-width:20vw !important};
.max-w-21{max-width:21vw !important};
.max-w-22{max-width:22vw !important};
.max-w-23{max-width:23vw !important};
.max-w-24{max-width:24vw !important};
.max-w-25{max-width:25vw !important};
.max-w-26{max-width:26vw !important};
.max-w-27{max-width:27vw !important};
.max-w-28{max-width:28vw !important};
.max-w-29{max-width:29vw !important};
.max-w-30{max-width:30vw !important};
.max-w-31{max-width:31vw !important};
.max-w-32{max-width:32vw !important};
.max-w-33{max-width:33vw !important};
.max-w-34{max-width:34vw !important};
.max-w-35{max-width:35vw !important};
.max-w-36{max-width:36vw !important};
.max-w-37{max-width:37vw !important};
.max-w-38{max-width:38vw !important};
.max-w-39{max-width:39vw !important};
.max-w-40{max-width:40vw !important};
.max-w-41{max-width:41vw !important};
.max-w-42{max-width:42vw !important};
.max-w-43{max-width:43vw !important};
.max-w-44{max-width:44vw !important};
.max-w-45{max-width:45vw !important};
.max-w-46{max-width:46vw !important};
.max-w-47{max-width:47vw !important};
.max-w-48{max-width:48vw !important};
.max-w-49{max-width:49vw !important};
.max-w-50{max-width:50vw !important};
.max-w-51{max-width:51vw !important};
.max-w-52{max-width:52vw !important};
.max-w-53{max-width:53vw !important};
.max-w-54{max-width:54vw !important};
.max-w-55{max-width:55vw !important};
.max-w-56{max-width:56vw !important};
.max-w-57{max-width:57vw !important};
.max-w-58{max-width:58vw !important};
.max-w-59{max-width:59vw !important};
.max-w-60{max-width:60vw !important};
.max-w-61{max-width:61vw !important};
.max-w-62{max-width:62vw !important};
.max-w-63{max-width:63vw !important};
.max-w-64{max-width:64vw !important};
.max-w-65{max-width:65vw !important};
.max-w-66{max-width:66vw !important};
.max-w-67{max-width:67vw !important};
.max-w-68{max-width:68vw !important};
.max-w-69{max-width:69vw !important};
.max-w-70{max-width:70vw !important};
.max-w-71{max-width:71vw !important};
.max-w-72{max-width:72vw !important};
.max-w-73{max-width:73vw !important};
.max-w-74{max-width:74vw !important};
.max-w-75{max-width:75vw !important};
.max-w-76{max-width:76vw !important};
.max-w-77{max-width:77vw !important};
.max-w-78{max-width:78vw !important};
.max-w-79{max-width:79vw !important};
.max-w-80{max-width:80vw !important};
.max-w-81{max-width:81vw !important};
.max-w-82{max-width:82vw !important};
.max-w-83{max-width:83vw !important};
.max-w-84{max-width:84vw !important};
.max-w-85{max-width:85vw !important};
.max-w-86{max-width:86vw !important};
.max-w-87{max-width:87vw !important};
.max-w-88{max-width:88vw !important};
.max-w-89{max-width:89vw !important};
.max-w-90{max-width:90vw !important};
.max-w-91{max-width:91vw !important};
.max-w-92{max-width:92vw !important};
.max-w-93{max-width:93vw !important};
.max-w-94{max-width:94vw !important};
.max-w-95{max-width:95vw !important};
.max-w-96{max-width:96vw !important};
.max-w-97{max-width:97vw !important};
.max-w-98{max-width:98vw !important};
.max-w-99{max-width:99vw !important};
.max-w-100{max-width:100vw !important};

//--------------------------------------------min-width------------------------------------------------------

.min-w-1{min-width:1vw !important};
.min-w-2{min-width:2vw !important};
.min-w-3{min-width:3vw !important};
.min-w-4{min-width:4vw !important};
.min-w-5{min-width:5vw !important};
.min-w-6{min-width:6vw !important};
.min-w-7{min-width:7vw !important};
.min-w-8{min-width:8vw !important};
.min-w-9{min-width:9vw !important};
.min-w-10{min-width:10vw !important};
.min-w-11{min-width:11vw !important};
.min-w-12{min-width:12vw !important};
.min-w-13{min-width:13vw !important};
.min-w-14{min-width:14vw !important};
.min-w-15{min-width:15vw !important};
.min-w-16{min-width:16vw !important};
.min-w-17{min-width:17vw !important};
.min-w-18{min-width:18vw !important};
.min-w-19{min-width:19vw !important};
.min-w-20{min-width:20vw !important};
.min-w-21{min-width:21vw !important};
.min-w-22{min-width:22vw !important};
.min-w-23{min-width:23vw !important};
.min-w-24{min-width:24vw !important};
.min-w-25{min-width:25vw !important};
.min-w-26{min-width:26vw !important};
.min-w-27{min-width:27vw !important};
.min-w-28{min-width:28vw !important};
.min-w-29{min-width:29vw !important};
.min-w-30{min-width:30vw !important};
.min-w-31{min-width:31vw !important};
.min-w-32{min-width:32vw !important};
.min-w-33{min-width:33vw !important};
.min-w-34{min-width:34vw !important};
.min-w-35{min-width:35vw !important};
.min-w-36{min-width:36vw !important};
.min-w-37{min-width:37vw !important};
.min-w-38{min-width:38vw !important};
.min-w-39{min-width:39vw !important};
.min-w-40{min-width:40vw !important};
.min-w-41{min-width:41vw !important};
.min-w-42{min-width:42vw !important};
.min-w-43{min-width:43vw !important};
.min-w-44{min-width:44vw !important};
.min-w-45{min-width:45vw !important};
.min-w-46{min-width:46vw !important};
.min-w-47{min-width:47vw !important};
.min-w-48{min-width:48vw !important};
.min-w-49{min-width:49vw !important};
.min-w-50{min-width:50vw !important};
.min-w-51{min-width:51vw !important};
.min-w-52{min-width:52vw !important};
.min-w-53{min-width:53vw !important};
.min-w-54{min-width:54vw !important};
.min-w-55{min-width:55vw !important};
.min-w-56{min-width:56vw !important};
.min-w-57{min-width:57vw !important};
.min-w-58{min-width:58vw !important};
.min-w-59{min-width:59vw !important};
.min-w-60{min-width:60vw !important};
.min-w-61{min-width:61vw !important};
.min-w-62{min-width:62vw !important};
.min-w-63{min-width:63vw !important};
.min-w-64{min-width:64vw !important};
.min-w-65{min-width:65vw !important};
.min-w-66{min-width:66vw !important};
.min-w-67{min-width:67vw !important};
.min-w-68{min-width:68vw !important};
.min-w-69{min-width:69vw !important};
.min-w-70{min-width:70vw !important};
.min-w-71{min-width:71vw !important};
.min-w-72{min-width:72vw !important};
.min-w-73{min-width:73vw !important};
.min-w-74{min-width:74vw !important};
.min-w-75{min-width:75vw !important};
.min-w-76{min-width:76vw !important};
.min-w-77{min-width:77vw !important};
.min-w-78{min-width:78vw !important};
.min-w-79{min-width:79vw !important};
.min-w-80{min-width:80vw !important};
.min-w-81{min-width:81vw !important};
.min-w-82{min-width:82vw !important};
.min-w-83{min-width:83vw !important};
.min-w-84{min-width:84vw !important};
.min-w-85{min-width:85vw !important};
.min-w-86{min-width:86vw !important};
.min-w-87{min-width:87vw !important};
.min-w-88{min-width:88vw !important};
.min-w-89{min-width:89vw !important};
.min-w-90{min-width:90vw !important};
.min-w-91{min-width:91vw !important};
.min-w-92{min-width:92vw !important};
.min-w-93{min-width:93vw !important};
.min-w-94{min-width:94vw !important};
.min-w-95{min-width:95vw !important};
.min-w-96{min-width:96vw !important};
.min-w-97{min-width:97vw !important};
.min-w-98{min-width:98vw !important};
.min-w-99{min-width:99vw !important};
.min-w-100{min-width:100vw !important};