.MuiTab-textColorPrimary.Mui-selected {
    color: #1976d2 !important;
}
.MuiTab-textColorPrimary {
    color: rgba(0, 0, 0, 0.54);
}
.MuiTabs-indicator {
    background-color: #1976d2 !important; 
}
.PrivateTabIndicator-colorPrimary-72 {
    background-color: #1976d2 !important; 
}
.MuiTab-root {
    padding: 6px 12px;
    overflow: hidden;
    position: relative;
    font-size: 0.875rem;
    max-width: 264px;
    min-width: 72px;
    box-sizing: border-box;
    min-height: 48px;
    text-align: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    font-weight: bolder !important;
}