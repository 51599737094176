
@mixin themeable($backgroundColor,$buttonColor,$DisabledColor, $sidebarColor,$sidebarHoverColor,$SelectedOption, $OptionsColor ) {

    .button-color{
        background-color: $buttonColor; 
    }

    .background-color{
        background-color: $backgroundColor ;
    }
    
    .disabled-button{
        background-color: $DisabledColor; 
    }
    .wms-ui-sidebar-a:hover, .active-a{
        background-color: $sidebarHoverColor;
    }
    
    .options {
        background: $sidebarColor;
    }
    .ui.inverted.button, .login-footer-link{   
         color: $buttonColor;
         border-color: $buttonColor;
         }
    .selected {
        background-color: $buttonColor;
      }
}
