.date{
	  text-align: left;
    font: Regular 12px/17px Open Sans !important;
    font-size: 11px !important;
    letter-spacing: 0.3px;
    color: #6E6E6E;
    margin-top: 4px;
  }
.type{
	  text-align: left;
    font: Regular 12px/17px Open Sans !important;
    font-size: 11px !important;
    letter-spacing: 0.3px;
    color: #6E6E6E;
    margin-bottom: 4px;
    margin-top: 4px;
	}
.name{
	  text-align: left;
    font: Semibold 18px/24px Open Sans;
    letter-spacing: 0.45px;
    opacity: 1;
    font-weight: bold !important;
    width: 15vw;
    border-bottom: 0px solid #eceeef !important;
    border-right: 0px solid #eceeef !important;
    // border: 1px solid #eceeef !important;
    // border-left: 0px solid #eceeef !important;
    // border-bottom: 0px solid #eceeef !important;
    // border-right: 0px solid #eceeef !important;
    // border-top: 1px solid #eceeef !important;
  }
.main-table{
  // border-top: 1px solid #eceeef !important;
  // border-left: 0px solid #eceeef !important;
  width: 100% !important;
  text-align: left !important;
  width: inherit;
  border-spacing: 0px;
  border-top: 0px solid #eceeef !important;
  border-left: 0px solid #eceeef !important;
  text-align: center;
}
.comment{
    font-size: 14px !important;
    font-weight: 500 !important;
    // width: 100vh;
    border-bottom: 1px solid #eceeef !important;
    border-right: 0px solid #eceeef !important;
    margin: 1em;
    width: 60vw;
    padding: 10px 10px;
    // padding-bottom: 15px !important;
    // border: 1px solid #eceeef !important;
    // border-left: 0px solid #eceeef !important;
    // border-bottom: 0px solid #eceeef !important;
    // border-right: 0px solid #eceeef !important;
    // border-top: 1px solid #eceeef !important;
    }
    
.table {
  width: 100% !important;
  max-width: 100%;
  margin-bottom: 1rem;
}
// table{
//   width: inherit;
//   border-spacing: 0px;
//   border-top: 0px solid #eceeef !important;
//   border-left: 0px solid #eceeef !important;
//   text-align: center;
// }

tr {
  //border-bottom: 1px solid black;
}
// td, th{
//   border-bottom: 0px solid #eceeef !important;
//   border-right: 0px solid #eceeef !important;
//   //border: 0.2px solid $blackColor;
// }
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top !important ;
  // border-top: 1px solid #eceeef !important;
}

.table thead th {
  vertical-align: bottom !important;
  // border-bottom: 2px solid #eceeef !important;
}

.table .table {
  background-color: #fff !important;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  // border: 1px solid #eceeef !important;
}

// .table-bordered th,
// .table-bordered td {
//   border: 1px solid #eceeef !important;
// }

// .table-bordered thead th,
// .table-bordered thead td {
//   border-bottom-width: 2px !important;
// }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #dff0d8;
}

.table-hover .table-success:hover {
  background-color: #d0e9c6;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d0e9c6;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d9edf7;
}

.table-hover .table-info:hover {
  background-color: #c4e3f3;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c4e3f3;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf8e3;
}

.table-hover .table-warning:hover {
  background-color: #faf2cc;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #faf2cc;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2dede;
}

.table-hover .table-danger:hover {
  background-color: #ebcccc;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ebcccc;
}

.thead-inverse th {
  color: #fff;
  background-color: #292b2c;
}

.thead-default th {
  color: #464a4c;
  background-color: #eceeef;
}

.table-inverse {
  color: #fff;
  background-color: #292b2c;
}

.table-inverse th,
.table-inverse td,
.table-inverse thead th {
  border-color: #fff;
}

.table-inverse.table-bordered {
  border: 0;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

// .table-responsive.table-bordered {
//   border: 0;
// }