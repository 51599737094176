//---------------------------------Margin------------------------------
.m-0{margin: 0vh 0vw !important;}
.m-1{ margin: 0.5vh 0.5vw !important; } 
.m-2{ margin: 1vh 1vw !important; } 
.m-3{ margin: 1.5vh 1.5vw !important; } 
.m-4{ margin: 2vh 2vw !important; } 
.m-5{ margin: 2.5vh 2.5vw !important; } 
.m-6{ margin: 3vh 3vw !important; } 
.m-7{ margin: 3.5vh 3.5vw !important; } 
.m-8{ margin: 4vh 4vw !important; } 
.m-9{ margin: 4.5vh 4.5vw !important; } 
.m-10{ margin: 5vh 5vw !important; }
.m-11{ margin: 5.5vh 5.5vw !important; } 
.m-12{ margin: 6vh 6vw !important; } 
.m-13{ margin: 6.5vh 6.5vw !important; } 
.m-14{ margin: 7vh 7vw !important; } 
.m-15{ margin: 7.5vh 7.5vw !important; } 
.m-16{ margin: 8vh 8vw !important; } 
.m-17{ margin: 8.5vh 8.5vw !important; } 
.m-18{ margin: 9vh 9vw !important; } 
.m-19{ margin: 9.5vh 9.5vw !important; } 
.m-20{ margin: 10vh 10vw !important; }

//---------------------------------Margin-left------------------------------
.ml-1{ margin-left: 0.5vw !important; } 
.ml-2{ margin-left: 1vw !important; } 
.ml-3{ margin-left: 1.5vw !important; } 
.ml-4{ margin-left: 2vw !important; } 
.ml-5{ margin-left: 2.5vw!important; } 
.ml-6{ margin-left: 3vw !important; } 
.ml-7{ margin-left: 3.5vw !important; } 
.ml-8{ margin-left: 4vw !important; } 
.ml-9{ margin-left: 4.5vw !important; } 
.ml-10{ margin-left: 5vw !important; } 
.ml-11{ margin-left: 5.5vw !important; } 
.ml-12{ margin-left: 6vw !important; } 
.ml-13{ margin-left: 6.5vw !important; } 
.ml-14{ margin-left: 7vw !important; } 
.ml-15{ margin-left: 7.5vw !important; } 
.ml-16{ margin-left: 8vw !important; } 
.ml-17{ margin-left: 8.5vw !important; } 
.ml-18{ margin-left: 9vw !important; } 
.ml-19{ margin-left: 9.5vw !important; } 
.ml-20{ margin-left: 10vw !important; }

//-----------------------------------margin-right-----------------------------------------------
.mr-1{ margin-right: 0.5vw !important; } 
.mr-2{ margin-right: 1vw !important; } 
.mr-3{ margin-right: 1.5vw !important; } 
.mr-4{ margin-right: 2vw !important; } 
.mr-5{ margin-right: 2.5vw !important; } 
.mr-6{ margin-right: 3vw !important; } 
.mr-7{ margin-right: 3.5vw !important; } 
.mr-8{ margin-right: 4vw !important; } 
.mr-9{ margin-right: 4.5vw !important; } 
.mr-10{ margin-right: 5vw !important; } 
.mr-11{ margin-right: 5.5vw !important; } 
.mr-12{ margin-right: 6vw !important; } 
.mr-13{ margin-right: 6.5vw !important; } 
.mr-14{ margin-right: 7vw !important; } 
.mr-15{ margin-right: 7.5vw !important; } 
.mr-16{ margin-right: 8vw !important; } 
.mr-17{ margin-right: 8.5vw !important; } 
.mr-18{ margin-right: 9vw !important; } 
.mr-19{ margin-right: 9.5vw !important; } 
.mr-20{ margin-right: 10vw !important; }

//-----------------------------------------margin-top-----------------------------------------------
.mt-1{ margin-top: 0.5vh !important; } 
.mt-2{ margin-top: 1vh !important; } 
.mt-3{ margin-top: 1.5vh !important; } 
.mt-4{ margin-top: 2vh !important; } 
.mt-5{ margin-top: 2.5vh !important} 
.mt-6{ margin-top: 3vh !important; } 
.mt-7{ margin-top: 3.5vh !important; } 
.mt-8{ margin-top: 4vh !important; } 
.mt-9{ margin-top: 4.5vh !important; } 
.mt-10{ margin-top: 5vh !important; } 
.mt-11{ margin-top: 5.5vh !important; } 
.mt-12{ margin-top: 6vh !important; } 
.mt-13{ margin-top: 6.5vh !important; } 
.mt-14{ margin-top: 7vh !important; } 
.mt-15{ margin-top: 7.5vh !important; } 
.mt-16{ margin-top: 8vh !important; } 
.mt-17{ margin-top: 8.5vh !important; } 
.mt-18{ margin-top: 9vh !important; } 
.mt-19{ margin-top: 9.5vh !important; } 
.mt-20{ margin-top: 10vh !important; }

//--------------------------------------margin-bottom-------------------------------------------
.mb-1{ margin-bottom: 0.5vh !important; } 
.mb-2{ margin-bottom: 1vh !important; } 
.mb-3{ margin-bottom: 1.5vh !important; } 
.mb-4{ margin-bottom: 2vh !important; } 
.mb-5{ margin-bottom: 2.5vh !important; } 
.mb-6{ margin-bottom: 3vh !important; } 
.mb-7{ margin-bottom: 3.5vh !important; } 
.mb-8{ margin-bottom: 4vh !important; } 
.mb-9{ margin-bottom: 4.5vh !important; } 
.mb-10{ margin-bottom: 5vh !important; } 
.mb-11{ margin-bottom: 5.5vh !important; } 
.mb-12{ margin-bottom: 6vh !important; } 
.mb-13{ margin-bottom: 6.5vh !important; } 
.mb-14{ margin-bottom: 7vh !important; } 
.mb-15{ margin-bottom: 7.5vh !important; } 
.mb-16{ margin-bottom: 8vh !important; } 
.mb-17{ margin-bottom: 8.5vh !important; } 
.mb-18{ margin-bottom: 9vh !important; } 
.mb-19{ margin-bottom: 9.5vh !important; } 
.mb-20{ margin-bottom: 10vh !important; }
