.meter { 
    height: 1rem;
    position: relative;
    background: #f3efe6;
    border-radius: 2px;
    display: block;
}
.valuebar-cointainer {
    width: 100%;
    display: flex !important;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, .2);
}
.valuebar{
    width:90%;
}
.progress-value {
    padding: 0px 5px;
    line-height: 20px;
    margin-left: 5px;
    font-size: .8em;
    color: #555;
    height: 18px;
    float: right;
}
.barcointainer {
    display: block;
    height: 100%;
}
.progress-bar-striped {
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-size: 1rem 1rem;
}
.progress {
    background-color: #e4c465;
    animation: progressBar 3s ease-in-out;
    animation-fill-mode:both;
}
.bg-success{
    background-color: #28a745; 
}
.bg-info{
    background-color: #17a2b8; 
}
.bg-warning{
    background-color: #ffc107; 
}
.bg-danger{
    background-color: #dc3545;
}
@keyframes progressBar {
  0% { width: 0; }
  100% { width: 100%; }
}