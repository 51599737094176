@import "../../common/variables.scss";
.ticket-page-font {
  font-family: $formfamilylabel;
  font-size: $fontSizeLabel;
}
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}
.wms-button {
  height: 2.5em ;
  width: 10em;
  border: none;
  border-radius: 6px;
  margin-right: 2%;
  color:$whiteColor !important;
  font-weight: 500;
  margin-top: 1.5em;
  margin-bottom: 1.5em !important;
}
.create-ticket-dropdown {
  color: #fff !important;
  font-weight: 100;
}
.option-div{
  justify-content: space-between;
}
.head {
  display: flex;
}
.people {
  font-weight: bold;
  line-height: 0.1em;
  margin-bottom: 1.5em;
}
.commentTable {
  width: 100%;
}
.dataTable-comment{
  text-align: left !important;
}
.addbtn {
  width:20%;
}
.footer-row{
  float: right;
  display: flex;
}
.commentBox {
  width: 100%;
}
.row-data{
  font-weight: lighter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  display: flex;
}
.title {
  width:75vh;
}
.error-msg  {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.error-tab {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4p
}
.title-div {
  display: flex;
}
.btn-row {
  margin-top: 5vh;
}
.form-label {
  font-weight: bolder;
  font-size: 17px !important;
}
.wms-selectbox {
  width: 90%;
}

.create-session-container {
  display: flex;
  flex-direction: column;
  width: 100em;
  min-height: 90.2vh;
}
.create-ticket-card-detail {
  margin: 2vh 2vw !important;
}
.update-ticket-card{
  overflow: unset !important;
  min-height: fit-content;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.centered{
  margin: 0 auto;
  width: 80%;
}
.chip-width {
  width: 90vh;
}
@media screen and (min-width: 480px) {
    .centered{
          margin: 0 auto;
          width: 95%;
        }
 }

.in-line {
  display: flex;
}
.label {
  @extend.ticket-page-font;
  width: 12vw;
  font-family: inherit;
  margin-top: 20px !important;
  font-size: 16px !important;
  font-weight: bold !important;
}
.PrivateTabIndicator-colorPrimary-143 {
  background-color: #338AFD !important;
}


.inputfield {
  width: 46vw;
  margin-top: 20px;
}
.form-row:after {
  content: "";
  display: table;
  clear: both;
  margin-top: 20px !important;
}
.margin-dis {
  margin-top: 20px !important;
}
.grid-container {
  display: grid;
  grid-template-columns: 200px auto auto auto;
  width: 100%;
}
.requiredfield {
  color: RGB(255,0,0);
}
.btn {
  background-color: $buttonColor;
  color: $whiteColor;
}
.option-button {
  display: flex;
}
.assignto {
  z-index: 3;
}
.close-alert {
  color: #721c24;
}
.assetid {
  display: flex;
}
.tab-width {
  width: auto !important;
  margin-bottom: -10px !important;
  background-color: $whiteColor;
}
// Ticket header 
.Ticket-header {
  justify-content: space-around;
}
.Ticket-header-detail-label {
font-style: Semibold 16px/22px Open Sans;
font-size: 16px !important;
font-weight: bold;
text-align: center;
}
.Ticket-header-detail-data {
font-size: 14px !important;
margin-top: 10px;
font-weight: 500 !important;
text-align: center;
} 
.scroll-commentBox{
  overflow: auto !important;
}
.select__menu {
  min-height: 65px;
  overflow: visible !important;
}
.comment-loader {
  position: relative !important;
}
.css-1wa3eu0-placeholder {
  color: #B0B0B0 !important;
  font-weight: 400 !important;
}
.team-header-label{
  width: 10vw;
}
.display-team {
  display: -webkit-box;
}
.selected-tab {
  color: $buttonColor;
}
.ticketid {
  font-style: Semibold 16px/22px Open Sans;
  font-size: 16px !important;
  font-weight: 500;
}
.MuiPaper-elevation1 {
    /* box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12); */
    box-shadow: 0px 3px 6px #C2DAF9 !important;
}
.MuiPaper-elevation4 {
  box-shadow: 0px 3px 6px #C2DAF9 !important;
}
.option-menu {
  justify-content: flex-end;
}
.active-option {
  color: #919191 !important;
}
.disable-option {
  color: #DBDBDB !important;
}
.menu-list {
  right: 40px !important;
}
.MuiOutlinedInput-multiline {
  padding: 18.5px 0px !important;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ececec;
  padding: 0;
}
.comment-table {
  margin-top: 10px;
  margin-bottom: 10px;
}

// .MuiOutlinedInput-input {
//   padding: 8px 15px!important;
// }