@import "../../common/variables.scss";
.nav-header {
  color:$blackColor;
  top: 0;
  right: 0;
  // position: fixed;
  // width: calc(100% - 15em) !important;
  z-index: 1200;
  margin: 2vh 2vw !important;
}
.nav-container {
  min-height: 56px;
  width: 100%;
  justify-content: space-between;
}
.navbar-buttons{
  border-radius: 8vw;
  margin-top:1.1vh ;
}
.navbar-buttons:hover{
  background-color: rgb(240, 240, 240); 
}
.inner-head{
  align-items: center;
 }
.nav-link{
  color:$blackColor;
  text-decoration: none;
  font-size:large;
}
.user_name{
  color:$blackColor;
  text-decoration: none;
  font-size:large;
}
.Nav-heading{
  font-size:1.75em;
}

.header-li {
  list-style: none;
}
.header-li-user{
  list-style: none;
 }

.userType {
  color: #808080;
  font-size: small;
}
.breadcrums {
  color: $blackColor;
  font-size: 1em;
}
.capital {
  text-transform: capitalize;
}
.active-breadcrum {
  color: $buttonColor;
  font-weight: bold;
}