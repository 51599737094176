.snackbarSuccess > div {
    background-color: #155724 !important;
    color: #ffffff !important;
}

.snackbarWarning > div {
    background-color: #856404 !important;
    color: #ffffff !important;
}

.snackbarError > div {
    background-color: #721c24 !important;
    color: #ffffff !important;
}

.snackbarInfo > div {
    background-color: #0c5460 !important;
    color: #ffffff !important;
}