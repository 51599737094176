@import "../../common/variables.scss";
.main-login-container{
        background-color: #ffffff;
        // background-image: linear-gradient(315deg, #6fb1a7 0%, #f5f7fa 74%);
        justify-content: center;
        align-items: center;
    }
.login-container{
//    background-color: #ffffff;
    // background-image: linear-gradient(315deg, #6fb1a7 0%, #f5f7fa 74%);
    justify-content: center;
    align-items: center;
}
.card-login {
    max-width: 40vw;
    align-self: center;
} 
.registration-select {
    margin-left: 15vw;
    width: 52%;
    text-align: left;
}
.sign-in-button{
    background-color: $whiteColor;
}
.card-header {
    /*background: #ff0000;*/
    font-family: sans-serif;
    font-size: 23px;
    border-bottom: 0;
    }

.login_btn{
    color: $whiteColor;
    padding: 6px 16px !important;
}
.login-btn-loader {
    position: inherit !important;
}
.loginError {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}
.errorTab {
    border: 1px solid transparent;
}

.form-control-registration-selectbox{
    background-color:$whiteColor;
    line-height: 0;
}

.login-footer-link{
    text-decoration: none;
}
.icon-left{
    background-image: url('../../assets/images/icon-left.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 1;
}
.icon-right{
    background-image: url('../../assets/images/icon-right.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 1;
    background-position-x: right;
    background-position-y: bottom;
}
.login-input{
    display: flex;
}
.forgot-password {
    cursor: pointer;
    font-size: smaller;
    color: rgb(255, 0, 0);
    font-family: Segoe UI;
}
.version {
    font-family: Segoe UI;
    font-size: smaller;
}