@import "./height.scss";
@import "./margin.scss";
@import "./padding.scss";
@import "./width.scss";
@import "../common/variables.scss";

.cursor-pointer{
    cursor: pointer;   
 }
 .cursor-default {
     cursor: default !important;
}
.float-none{
    float: none;
}
.float-left{
    float: left;
}
.float-right{
    float: right;
}
.float-initial{
    float: initial;
}
.float-inherit{
    float: inherit;
}


.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.text-left{
    text-align: left;
}
.text-justify{
    text-align: justify;
}
.text-initial{
    text-align: initial;
}
.text-inherit{
    text-align: inherit;
}


.font-weight-normal{
    font-weight: normal;
}
.font-weight-bold{
    font-weight: bold;
}
.font-weight-bolder{
    font-weight: bolder;
}
.font-weight-lighter{
    font-weight: lighter;
}
.font-weight-inherit{
    font-weight: inherit;
}
.font-weight-initial{
    font-weight: initial;
}
.font-weight-100{
    font-weight: 100;
}
.font-weight-200{
    font-weight: 200;
}
.font-weight-300{
    font-weight: 300;
}
.font-weight-400{
    font-weight: 400;
}
.font-weight-500{
    font-weight: 500;
}
.font-weight-600{
    font-weight: 600;
}
.font-weight-700{
    font-weight: 700;
}
.font-weight-800{
    font-weight: 800;
}
.font-weight-900{
    font-weight: 900;
}


.display-inline{
    display: inline;
}
.display-inherit{
    display: inherit;
}
.display-initial{
    display: initial;
}
.display-none{
    display: none;
}
.display-block{
    display: block;
}
.display-flex{
    display: flex;
}
.display-contents{
    display: contents;
}
.display-inlineGrid{
    display: inline-grid;
}
.display-inlineFlex{
    display: inline-flex;
}
.display-inlineBlock{
    display: inline-block;
}
