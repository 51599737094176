@import "../../common/variables.scss";
.Feedback-container{
    flex-direction: column;
}
.inner-container{
    flex-direction: column;
}
.chart-container{
  flex-direction: row;
  justify-content: space-evenly;
}
.custom-tooltip{
  background-color: $whiteColor !important;
}
.tooltip-container{
  background-color: $whiteColor !important;
}
.tooltip-chart{
  box-sizing: border-box !important;
  font-weight: 500 !important;
  background-color: $whiteColor !important;
}
.Feedback-button{
    text-transform: capitalize !important;
    border-radius: 0px !important;
  }

/* .Feedback-header {
    display: flex;
    flex-wrap: wrap;
}
  
  .Feedback-header > div {
    width: 31%;
    margin: 1%;
  } */
.Feedback-head-card{
    width: 100%;
}

.Feedback-head-container{
  justify-content: space-evenly;
}
.custom-tooltip{
  height: fit-content;
  background-color: $whiteColor;
  width: 200px;
}
