.Feedback-header {
    flex-wrap: wrap;
    width: 74vw !important;
    justify-content: space-around;
}
.card-detail-label {
  font-style: Semibold 18px/24px Open Sans;
  font-size: 18px !important;
  font-weight: bold;
}
.card-detail-data {
 // font-style: Regular 14px/19px Open Sans;
 font-size: 13px !important;
  margin-top: 10px;
  font-weight: 400 !important;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
} 
  // .Feedback-header > div {
  //   width: 31%;
  //   margin: 1%;
  // }
