@import "../../common/variables.scss";
.profile-container{
    padding-top: 2%;
    display: flex;
    justify-content: space-around;
}
.p-row{
    display: flex;
    align-items: center;
    margin: 2vh 0vw;
}
.error-color{
   color: #ff0000;
}

.p-label{
    font-weight: bold;
    font-size: 1.1em;
}

.p-para{
    color: $blackColor;
    font-weight: lighter;
     padding: 10px;
    margin: 0px;
    font-size: 1.1em;
}

.profile-edit-btn{
    margin: 1vh 0vw !important;
}


.profile-save-btn{
    color: $whiteColor;
}

.profile-img img{
    margin-left: 0.75vw;
    width: 15vw;
    }