.wms-ui-Dropdown {
    top: 100%;
    left: 0;
    width: 12vw;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.04);
 }
 .wms-ui-dropdown-button{
     width:12vw;
     text-transform: capitalize !important;
 } 
.listcontent {
  position: absolute;
  background-color: #FFF !important;
  width: 12vh !important;
  border-style: solid;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  box-shadow: 0px 3px 6px #C2DAF9 !important;
}
.listcontent:focus { outline: none !important; }
.wms-ui-ul {
    list-style: none;
    padding: 0;
    margin: 0;
    
  }
  :focus { outline: none; }
  .wms-ui-li {
    padding: 1.2vh 1.2vw;
  }
  
  .wms-ui-li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }