/* input[type=text] {
    width: 20%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid black;
    font-size: 20px;
  }

  input[type=email]:focus {
    border: 3px solid #555;
  }
  
  input[type=password ],[type=email ],[type=Number ] {
    width: 20%;
    font-size: 20px;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 3px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }
  input[type=password],[type=email ],[type=Number ]:focus {
    border: 3px solid #555;
  }


  input[type=submit ]{
      color:white;
      background-color: green;
      padding: 1%;
      border-color:green;
      border-radius: 10px;
  }
  input[type=date] {
      font-size: 20px;
    border: 2px solid grey;
    border-radius: 5px;
    background-color: #fff;
    padding: 3px 5px;
    box-shadow: inset 0 3px 6px rgba(0,0,0,0.1);
    width: 20%;
    margin-top:1%;
   
  }
  
input[type="radio"]  {
    color:#f2f2f2;
    font-family:Arial, sans-serif;
    font-size:14px;
    display:inline-block;
    width:19px;
    height:19px;
    margin-top:1%;
    vertical-align:middle;
    cursor:pointer;
} */



input[type="file"] {
  display: none;
}

.fileLabel{
  padding: 10px;
  background: green; 
  display: table;
  color: #fff;
  border-radius: 20%;
   }
// .wms-ui-InputField {
//   padding: 10px 10px;
// }
