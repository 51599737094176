.wms-ui-dataGrid
{
    height: 40vh;
    font-size: 12px;
   
}
.data-grid-1{
    width: 95vh;
}
.ag-header{
    font-size: 1.2em !important;
}
.ag-row .ag-cell {
    display: block;
    align-items: center;
    text-align: center;
    padding-top: 1vh !important;
  }

